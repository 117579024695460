<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'queryGlossary',
  watch: {
    glossary() {
      this.$store.commit('setStrapiResult', { vuexModule: 'glossary', state: 'glossary', data: this.glossary?.glossar?.data?.attributes?.Begriffe });
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_STRAPI,
      cachePolicy: 'cache-first',
    });

    const store = useStore();

    const queryGlossary = gql`
    query($id: ID) {
    glossar(id: $id) {
      data {
        attributes {
          Bezeichnung
          Begriffe(pagination: { limit: 9999 }) {
            Begriff
            Definition
          }
        }
      }
    }
  }
    `;

    const variables = reactive({
      id: computed(() => store.state.settings.currentCourseGlossaryID),
    });

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { data: glossary } = useQuery({
      query: queryGlossary,
      variables,
      context,
    });
    return { glossary };
  },
};
</script>
