<template>
<div>
</div>
</template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'formSubmissionMutation',

  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
    });

    const formSubmissionMutation = gql`
      mutation($user: String!, $kurs: String!, $begriff: String!, $definition: String!) {
        createKontaktformular(data: { 
          User: $user, 
          Kurs: $kurs,
          Begriff: $begriff, 
          Definition: $definition }) {
          data {
            id
          }
        }
      }
      `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { execute: createFormSubmission } = useMutation(formSubmissionMutation, {
      context,
    });

    store.commit('setStrapiExecutionHandler', { item: 'createFormSubmission', result: createFormSubmission });
  },
};
</script>
